import React, { useState, useEffect } from 'react';
import BaseWizard from '../../component/Wizard';
import CandidateService from '../../service/CandidateService';
import { useParams, Link, useHistory } from 'react-router-dom';
import Loader from '../../component/Loader';

import PersonalStep from './WizardStep/Personal';
import QualificationStep from './WizardStep/Qualifications';
import CareerStep from './WizardStep/Careers';
import AddressStep from './WizardStep/Addresses';
import ProfessionalStep from './WizardStep/Professional';

const stepList = [
    { displayName: "Personal", name: 'personal', type: PersonalStep },
    { displayName: "Professional", name: 'professional', type: ProfessionalStep },
    { displayName: "Eductional", name: 'qualifications', type: QualificationStep },
    { displayName: "Careers", name: 'careers', type: CareerStep },
    { displayName: "Addresses", name: 'addresses', type: AddressStep },
]

const CandidateUpdate = () => {
    const { id, userType } = useParams()
    const wizardConfig = { name: 'Candidate', stepCount: 5, steps: stepList, isProgression: true }
    const [candidateData, setCandidateData] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getCandidateData();
    }, [])

    useEffect(() => {
        if (candidateData) {
            setIsLoading(false)
        }
    }, [candidateData])

    const getCandidateData = async () => {
        try {
            const response = await CandidateService.getCandidateDetails(id);
            setCandidateData(response.data.result);
        } catch (err) {
            setIsLoading(false);
            // console.log(err);
        }

    }
    if (isLoading) return <Loader />;

    return (
        <div className="container-fluid p-0 m-0">
            <BaseWizard config={wizardConfig} data={candidateData}></BaseWizard>
        </div>
    );
}

export default CandidateUpdate;