import React from "react";

function Loader() {
  return (
    <div className="overlay d-block">
      <div className="overlay-content">
        <div className="loader-content">
          {/* <div className="loader-wrapper">
                      <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                      </div>
                  </div>
                  <div className="text-msg"> 
                    <p>Loading ... </p>
                  </div> */}
          <div className="ring">
            loading..
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
