import React, { useState, useEffect } from "react";
import Countcard from "../component/Countcard";
import AuthService from "../service/AuthService";
import { useHistory, Link, useLocation } from "react-router-dom";
import WorkIcon from "@material-ui/icons/Work";
import PersonIcon from "@material-ui/icons/Person";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ClientService from "../service/ClientService";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import UpdateIcon from "@material-ui/icons/Update";
import Moment from "react-moment";
import Loader from "../component/Loader";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import userImg from "./../../assets/imgs/male-avatar.png";
import "react-circular-progressbar/dist/styles.css";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { SuccessToaster } from "../component/modal/SuccessModal";
import ErrorModal from "../component/modal/ErrorModal";
import ProfileCompletionModal from "../component/modal/ProfileCompletionModal";

const isLoggedIn = () => {
  const history = useHistory();
  if (!AuthService.isLoggedIn()) {
    history.push("/cli/login");
  }
};

const CandidateDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [activityLog, setActivityLog] = useState([]);
  const [logChart, setLogChart] = useState([]);
  const [jobListTemplate, setJobListTemplate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobsList, setJobsList] = useState([]);
  const [suggestedJobsList, setsuggestedJobsList] = useState([]);
  const [suggestedJobsListTemplate, setsuggestedJobsListTemplate] = useState(
    []
  );
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [profileCompletionObj, setProfileCompletionObj] = useState({ show: false, error: {} });
  const [errorObj, setErrorObj] = useState({ show: false, error: {} });
  const [successObj, setSuccessObj] = useState({ show: false, success: {} });
  const location = useLocation();
  const userPrifix = location.pathname.split("/")[1];

  isLoggedIn();

  useEffect(() => {
    getDashboardData();
    getUserActivity();
  }, []);

  useEffect(() => {
    getJobs();
    getSuggestedJobs();
  }, [jobsList, suggestedJobsList]);

  const getLastUpdated = (date) => {
    var today = new Date();
    var date = new Date(date);
    var seconds = Math.floor((today - date) / 1000);
    var interval;
    var lastUpdOn;
    if (Math.floor(seconds / 31536000) >= 1) {
      interval = Math.floor(seconds / 31536000);
      if (interval == 1) {
        lastUpdOn = interval + " year";
      } else {
        lastUpdOn = interval + " years";
      }
    } else if (Math.floor(seconds / 2592000) >= 1) {
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        lastUpdOn = interval + " month";
      } else {
        lastUpdOn = interval + " months";
      }
    } else if (Math.floor(seconds / 86400) >= 1) {
      interval = Math.floor(seconds / 86400);
      if (interval == 1) {
        lastUpdOn = interval + " day";
      } else {
        lastUpdOn = interval + " days";
      }
    } else if (Math.floor(seconds / 3600) >= 1) {
      interval = Math.floor(seconds / 3600);
      if (interval == 1) {
        lastUpdOn = interval + " hour";
      } else {
        lastUpdOn = interval + " hours";
      }
    } else if (Math.floor(seconds / 60) >= 1) {
      interval = Math.floor(seconds / 60);
      if (interval == 1) {
        lastUpdOn = interval + " minute";
      } else {
        lastUpdOn = interval + " minutes";
      }
    } else if (Math.floor(seconds) >= 1) {
      interval = Math.floor(seconds);
      if (interval == 1) {
        lastUpdOn = interval + " second";
      } else {
        lastUpdOn = interval + " seconds";
      }
    }
    return lastUpdOn;
  };

  const getDashboardData = async () => {
    setLoading(true);
    const response = await ClientService.getDashboardCounts();
    if (response.status === 200) {
      if (response["data"]["result"]["counts"]["profileCompletion"] < 100) {
        setProfileCompletionObj({ show: true, error: { 'message': "Your Profile is only " + response["data"]["result"]["counts"]["profileCompletion"] + "% completed till now." } });
      }
      setDashboardData(response["data"]["result"]);
      setJobsList(response["data"]["result"]["recentPostedJobs"]);
      setsuggestedJobsList(response["data"]["result"]["submittedJobs"]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getUserActivity = async () => {
    setLoading(true);
    const response = await ClientService.getUserActivityLog();
    if (response.status === 200) {
      setActivityLog(response["data"]["result"]);
      let logChar = response["data"]["result"].map((log, i) => {
        return (
          <div key={"log" + i}>
            <div className="timeline-month">
              <Moment format="MMM DD, YYYY">{log.date}</Moment>
              {/* <span>3 Entries</span> */}
            </div>
            {log.messages ? (
              log.messages.map((msg, i) => {
                return (
                  <div className="timeline-section fs-14" key={i}>
                    <div className="row m-2">
                      <div className="col-auto time">
                        <Moment format="hh:mm">{msg.createdOn}</Moment>
                      </div>
                      <div className="col">
                        {msg.message.toLowerCase().includes('applied for job') ? <span className="job-appliied-log-highlighter" title={msg.detailedMessage}>{msg.message}</span> : msg.message.toLowerCase().includes('you logged in') || msg.message.toLowerCase().includes('you have been logged out') ? <span className="signin-appliied-log-highlighter" title={msg.detailedMessage}>{msg.message}</span> : <span title={msg.detailedMessage}>{msg.message}</span>}
                        {/* <span title={msg.detailedMessage}>{msg.message}</span> */}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h4>No Logs Found</h4>
            )}
          </div >
        );
      });

      setLogChart(logChar);
    } else {
      setLoading(false);
    }
  };

  const getJobs = () => {
    let jobs = suggestedJobsList.map((job, i) => {
      return (
        <li className="job-list col-12 py-1" key={"job" + i}>
          <Link to={`/can/cand-jobs/view/${job.id}`}>
            <div className="container-fluid px-0">
              <div className="list-content col-12 row no-gutters align-items-center px-0">
                <div className="col-auto pl-2">
                  <img
                    className="user-image"
                    src={
                      job.client && job.client.logo
                        ? job.client.logo.href
                        : null
                    }
                  />
                </div>

                <div className="user-name col px-3">
                  <b>{`${job.client.name} | ${job.title}`}</b>
                  <p className="m-0">
                    <span className="fs-14 red-color">Role : </span>
                    <span className="f-sm">{`${job.role}`}</span>
                  </p>
                  <p className="m-0">
                    <span className="fs-14 red-color">Category : </span>
                    <span className="f-sm">{`${job.category}`}</span>
                  </p>
                  <p className="m-0">
                    <span className="fs-14 red-color">Location : </span>
                    <LocationOnIcon className="job-loc-icon" />
                    <span className="f-sm">{`${job.jobLocation && job.jobLocation.town ? job.jobLocation.town : job.jobLocation ? job.jobLocation.city : null
                      }`}</span>
                  </p>
                  <p className="m-0">
                    <span className="fs-14 red-color">Posted On : </span>
                    <span className="f-sm">
                      <Moment format="DD-MM-YYYY">{job.createdOn}</Moment>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </li>
      );
    });
    setJobListTemplate(jobs);
  };

  const getSuggestedJobs = () => {
    let jobs = suggestedJobsList.map((job, i) => {
      return (
        <li
          className={[
            "job-list col-12 py-1",
            job.isHotJob ? "isHotJob" : null,
          ].join(" ")}
          key={"job" + i}
        >
          <Link to={`/can/cand-jobs/view/${job.id}`}>
            <div className="container-fluid px-0">
              <div className="list-content col-12 row no-gutters align-items-center px-0">
                <div className="col-auto pl-2">
                  <img
                    className="user-image"
                    src={
                      job.client && job.client.logo
                        ? job.client.logo.href
                        : null
                    }
                  />
                </div>

                <div className="user-name col text-truncate px-3">
                  <b>{`${job.title}`}</b>
                  <p className="m-0">
                    <span className="fs-14 red-color">Role : </span>
                    <span className="f-sm">{`${job.role}`}</span>
                  </p>
                </div>
                <div className="col d-block text-truncate">
                  <span className="fs-14 green-color">Category : </span>
                  <span className="f-sm">
                    <b>{`${job.category}`}</b>
                  </span>
                </div>
                <div className="col-2 d-block text-truncate">
                  <span className="f-sm">
                    <LocationOnIcon className="job-loc-icon" />
                    {`${job.jobLocation ? job.jobLocation.town : null}`}
                  </span>
                </div>
                <div className="col d-block text-truncate">
                  <span className="fs-14">Posted On : </span>
                  <b>
                    <span className="f-sm">
                      <Moment format="YYYY-MM-DD">{job.createdOn}</Moment>
                    </span>
                  </b>
                </div>
              </div>
            </div>
          </Link>
        </li>
      );
    });
    setsuggestedJobsListTemplate(jobs);
  };

  const changePassword = async () => {
    setLoading(true);
    let data = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      shouldLogout: false,
    };

    ClientService.changePassword(data).then(
      (response) => {
        setChangePasswordModal(false);
        setLoading(false);
        setSuccessObj({ show: true, msg: "Password Changed Successfully" });
      },
      (err) => {
        setLoading(false);
        setChangePasswordModal(false);
        setErrorObj({ show: true, error: err.data });
      }
    );
  };

  const closePopup = () => {
    setSuccessObj({ show: false, msg: "" });
  };

  if (loading) return <Loader />;

  return (
    <div>
      <div id="staff-dashboard" className="content-wrapper client-dashboard">
        <div className="dashboard-container">
          <h3>Candidate Dashboard</h3>
          <div className="container-fluid p-0 mt-4">
            {dashboardData ? (
              <div className="row px-2">
                <div className="count-width">
                  <Countcard
                    count={`${dashboardData.counts.profileCompletion} %`}
                    msg={"Profile Completion"}
                    name={"total-count"}
                    icon={PersonIcon}
                  />
                </div>
                <div className="count-width">
                  <Countcard
                    count={
                      <Moment style={{ fontSize: "16px" }} format="MMM DD,YYYY">
                        {dashboardData.counts.lastUpdatedOn}
                      </Moment>
                    }
                    msg={"Last Updated On"}
                    name={"inactive-count"}
                    icon={UpdateIcon}
                  />
                </div>
                <div className="count-width">
                  <Countcard
                    count={dashboardData.counts.appliedCount}
                    msg={"Applied Jobs"}
                    name={"active-count"}
                    icon={WorkIcon}
                  />
                </div>
                <div className="count-width">
                  <Countcard
                    count={dashboardData.counts.shortlistedCount}
                    msg={"Shortlisted"}
                    name={"new-count"}
                    icon={AccountBalanceWalletIcon}
                  />
                </div>
                <div className="count-width">
                  <Countcard
                    count={dashboardData.counts.newPostings}
                    msg={"New Posted Jobs"}
                    name={"inactive-count"}
                    icon={NotificationsIcon}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {dashboardData ? (
            <div className="row pt-4">
              <div className="col-12">
                <div className="card count-card">
                  <div className="card-body">
                    <div className="row pb-1">
                      <div className="col-auto header-text">
                        <h6 className="mb-2">Profile</h6>
                      </div>
                    </div>
                    <div className="row no-gutters d-flex align-items-center">
                      <div className="col-3">
                        <img
                          className="profile-img"
                          src={
                            dashboardData.profile.profilePic
                              ? dashboardData.profile.profilePic.href
                              : userImg
                          }
                        ></img>
                      </div>
                      <div className="col-5 text-left">
                        <p className="name">
                          <span className="fw-500">Name : </span>
                          {dashboardData.profile.firstName}{" "}
                          {dashboardData.profile.lastName}
                        </p>
                        <p className="name">
                          <span className="fw-500">Email : </span>
                          {dashboardData.profile.email}
                        </p>
                        <p className="name">
                          <span className="fw-500">Contact : </span>
                          {dashboardData.profile.contactNumber}
                        </p>
                        <p className="name">
                          <span className="fw-500">Gender : </span>
                          {dashboardData.profile.gender}
                        </p>
                      </div>
                      <div className="col-4">
                        <h4 className="name">
                          Hi, {dashboardData.profile.firstName}{" "}
                          {dashboardData.profile.lastName}
                        </h4>
                        <div className="progression-container row col-12 my-3">
                          <div className="progress col-12 p-0">
                            <div
                              className={dashboardData.counts.profileCompletion == 100 ? `progress-bar` : `progress-bar-danger`}
                              role="progressbar"
                              style={{
                                width:
                                  dashboardData.counts.profileCompletion + "%",
                              }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {dashboardData.counts.profileCompletion}%
                            </div>
                          </div>
                          <div className="col-12 row progression-text mx-0 px-0 py-2">
                            <div className="col-auto text p-0">
                              {dashboardData.counts.profileCompletion}% profile
                              completed
                            </div>
                            <div className="col"></div>
                            <div className="col-auto text p-0">
                              Finish Profile
                            </div>
                          </div>
                        </div>
                        <Link
                          to={`/can/profile-view/${dashboardData.profile.id}`}
                        >
                          <button
                            type="button"
                            className="btn btn-primary mr-2"
                            style={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            View Full profile
                          </button>
                        </Link>
                        {/* <br /> */}
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => setChangePasswordModal(true)}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row pt-4">
            <div className="col-7">
              <div className="card count-card job-posted-container">
                <div className="card-body">
                  <div className="row">
                    <div className="col-auto header-text mb-1">
                      <h6>Recent Job Posted</h6>
                    </div>
                    <div className="col"></div>
                    <div className="col-auto color-grey ">
                      <Link to="/can/cand-jobs">Know More</Link>
                    </div>
                  </div>
                  <div className="row job-posted-lists no-gutters cand-job-list">
                    {jobListTemplate}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5 pl-0">
              <div className="card count-card staff-dashboard-profile">
                <div className="card-body">
                  <div className="row">
                    <div className="col-auto header-text mb-1">
                      <h6>Your Activity Log</h6>
                    </div>
                    {/* <div className="col"></div>
                                        <div className="col-auto color-grey fs-14">Know More</div> */}
                  </div>
                  <div className="row position-relative cand-log-timeline-container">
                    <div className="container">
                      <div className="timeline mt-0">{logChart}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-4 h-500">
            <div className="col-12">
              <div className="card count-card job-posted-container h-100">
                <div className="card-body">
                  <div className="row pb-1">
                    <div className="col-auto header-text">
                      <h6>Applied & Shortlisted Jobs</h6>
                    </div>
                    <div className="col"></div>
                    <div className="col-auto color-grey ">
                      <Link to="/can/cand-jobs">Know More</Link>
                    </div>
                  </div>
                  <div className="row job-posted-lists no-gutters client-job-list">
                    {suggestedJobsListTemplate}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        onClose={() => {
          setChangePasswordModal(false);
        }}
        open={changePasswordModal}
        className="client-chng-pass"
      >
        <Grid container>
          <Grid item sm={12} align="center">
            <h5 className="text-center mt-3">New Password</h5>
            <hr className=""></hr>
            <form autoComplete="off">
              <TextField
                type="password"
                className="mt-3 width100"
                label="Current Password"
                variant="outlined"
                onChange={(event) => setCurrentPassword(event.target.value)}
              />
              <br></br>
              <TextField
                type="password"
                className="mt-3 width100"
                label="New Password"
                variant="outlined"
                onChange={(event) => setNewPassword(event.target.value)}
              />
              <br></br>
              <TextField
                type="password"
                className="mt-3 width100"
                label="Confirm Password"
                variant="outlined"
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
              <div className="text-center my-4">
                <Button
                  variant="contained"
                  className="mx-2"
                  onClick={() => setChangePasswordModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changePassword}
                >
                  Submit
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </Dialog>

      <SuccessToaster
        data={successObj}
        onClose={() => setSuccessObj({ show: false, success: {} })}
      ></SuccessToaster>
      <ErrorModal
        data={errorObj}
        onConfirmAction={() => setErrorObj({ show: false, error: {} })}
      ></ErrorModal>
      <ProfileCompletionModal
        data={profileCompletionObj}
        onConfirmAction={() => setProfileCompletionObj({ show: false, error: {} })}
      ></ProfileCompletionModal>
    </div>
  );
};

export default CandidateDashboard;
