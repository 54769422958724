import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import CandidateService from '../../../service/CandidateService';
import { useParams, Link, useHistory } from 'react-router-dom';
import Autocomplete from 'react-select';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import UseForm from '../../../service/FormService';
import $ from 'jquery';
import ErrorModal from "../../../component/modal/ErrorModal"
import Loader from '../../../component/Loader';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileViewerModal from '../../../component/modal/FileViewerPopup';


const initialSchema = {
    "experienceInYr": { value: "", error: "" },
    "experienceInMnth": { value: "", error: "" },
    "currentCtcInLkh": { value: "", error: "" },
    "currentCtcInThnd": { value: "", error: "" },
    "expectedCtcInLkh": { value: "", error: "" },
    "expectedCtcInThnd": { value: "", error: "" },
    "preferredLocations": { value: false, error: "" },
    "resume": { value: "", error: "" },
    "state": { value: "", error: "" },
    "city": { value: "", error: "" },
    "town": { value: "", error: "" },
    "category": { value: "", error: "" },
    "role": { value: "", error: "" },
}

const validationStateSchema = {
    "experienceInYr": { required: true },
    "experienceInMnth": { required: true },
    "currentCtcInLkh": { required: true },
    "currentCtcInThnd": { required: true },
    "expectedCtcInLkh": { required: false },
    "expectedCtcInThnd": { required: false },
    "preferredLocations": { required: true },
    "resume": { required: true },
    "state": { required: true },
    "city": { required: true },
    "town": { required: false },
    "category": { required: true },
    "role": { required: true },

}

const ProfessionalStep = forwardRef((props, ref) => {
    const { id } = useParams();
    const history = useHistory();
    const [stateSchema, setStateSchema] = useState(initialSchema);
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorObj, setErrorObj] = useState({ show: false, error: {} });
    const [isChanged, setChanged] = useState(false);
    const [cityList, setCityList] = useState([]);
    const [cityData, setCityData] = useState({});
    const [selectedCity, setSelectedCity] = useState([]);
    const [cityLoading, setCityLoading] = useState(true);
    const [fileViewObj, setFileViewObj] = useState({ show: false, file: {} });
    const [stateList, setStateList] = useState([]);
    const [cityLists, setCityLists] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [roleList, setRoleList] = useState([]);


    useEffect(() => {
        getAllCityList();
        getStateList();
        getCategoryList();
        if (props.wizard && props.wizard.professional) {
            upDateInitialData();
            props.canProceed(true);
        }
        else {
            props.canProceed(false);
        }
        setCityLoading(false)
    }, [])

    const getStateList = async () => {
        try {
            const response = await CandidateService.getStateList();
            setStateList(response.data.result);
        } catch (err) {
            // console.log(err)
        }
    }

    const getCategoryList = async () => {
        try {
            const response = await CandidateService.getCategoryList();
            setCategoryList(response.data.result);
        } catch (err) {
            setLoading(false);
        }
    }

    const onStateChange = (e) => {
        e.persist();
        handleOnChange({ target: { name: 'state', value: e.target.value } });
        handleOnChange({ target: { name: 'city', value: '' } });
        handleOnChange({ target: { name: 'town', value: '' } });
        getCityByStateId(e.target.value);
    }

    const categoryChanges = (data) => {
        handleOnChange({ target: { name: 'category', value: data ? { id: data.id, name: data.name } : '' } });
        handleOnChange({ target: { name: 'role', value: '' } });

        if (data && data.id)
            getRoleList(data.id)
    }

    const getRoleList = async (categoryId, param) => {
        try {
            const response = await CandidateService.getRoleList(categoryId, param);
            setRoleList(response.data.result);
        } catch (err) {
            // console.log(err);
        }
    }
    const roleChanges = (data) => {
        handleOnChange({ target: { name: 'role', value: data ? { id: data.id, name: data.name } : '' } });
    }

    const getCityByStateId = async (stateId) => {
        try {
            const response = await CandidateService.getCityList(stateId);
            setCityLists(response.data.result);
        } catch (err) {
            // console.log(err);
        }
    }
    const getAllCityList = async (param, url) => {
        try {
            const response = await CandidateService.getCityListByPagination(param, url);
            // console.log(response);
            if (url) {
                let tempDataArr = cityList;
                tempDataArr = tempDataArr.concat(response.data.result.data);
                response.data.result.data = tempDataArr;
            }
            setCityData(response.data)
            setCityList($.extend(true, [], response.data.result.data));
        } catch (err) {
            // console.log(err)
        }

    }

    const paginateCityList = (e) => {
        if (cityData.detail.links && cityData.detail.links.next) {
            setCityLoading(true)
            getAllCityList("", cityData.detail.links.next);
            setCityLoading(false)
        }

    }

    const onCityInputChange = (param, actionType, list) => {
        if (actionType.action && actionType.action === 'input-change') {
            setCityList([]);
            param ? getAllCityList(param) : getAllCityList();
        }
        else if (actionType.action === 'menu-close' && !selectedCity.length > 0)
            getAllCityList()
    }

    const cityChange = (cities) => {
        // console.log("cities change", cities)
        if (cities && cities.length > 0) {
            setSelectedCity(cities);
            handleOnChange({ target: { name: 'preferredLocations', value: cities } })
        }
        else {
            setSelectedCity([]);
            handleOnChange({ target: { name: 'preferredLocations', value: '' } })
        }


    }

    const customFilter = (option, inputValue) => {
        if (option.data.name && inputValue) {
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    }

    const editData = () => {
        setEditMode(true);
        props.canProceed(false);
    }

    const upDateInitialData = () => {
        const data = props.wizard.professional;
        let schema = $.extend(true, {}, stateSchema);
        Object.keys(schema).forEach(key => {
            if (data.hasOwnProperty(key)) {
                if (key === 'category' || key === 'role') {
                    schema[key].value = { id: data[key].id, name: data[key].name };
                }
                else if (key === 'preferredLocations') {
                    schema[key].value = data[key].map(item => { return { id: item.id, name: item.name } });
                    setSelectedCity(schema[key].value);
                } else {
                    schema[key].value = data[key];
                }

            }
        })
        if (data.currentLocation) {
            schema.state.value = data.currentLocation.state ? data.currentLocation.state.id : '';
            schema.city.value = data.currentLocation.city ? data.currentLocation.city.id : '';
            schema.town.value = data.currentLocation.town ? data.currentLocation.town : '';
            if (data.currentLocation.state)
                getCityByStateId(data.currentLocation.state.id);
        }
        setStateSchema(schema);

    };

    useImperativeHandle(ref, () => ({
        submit() {
            if (isChanged) {
                const requestPayload = { 'currentLocation': { state: '', city: '', town: '' } };
                Object.keys(state).forEach(key => {
                    if (key === 'resume') {
                        requestPayload[key] = state[key].value ? state[key].value.id : null;
                    } else if (key === "preferredLocations") {
                        // console.log(state[key].value);
                        requestPayload[key] = state[key].value.map(list => list.id)
                    } else if (key === "category") {
                        requestPayload['categoryId'] = state[key].value ? state[key].value.id : null;
                    } else if (key === "role") {
                        requestPayload['roleId'] = state[key].value ? state[key].value.id : null;
                    } else if (key === "city" || key === "town" || key === "state") {
                        requestPayload.currentLocation[key] = state[key].value || null;
                    } else {
                        requestPayload[key] = state[key].value || null;
                    }
                })
                if (props.wizard.professional) {
                    CandidateService.updateAdditionalDetails(id, requestPayload).then((res) => {
                        if (res.status === 201 || res.status === 200) {
                            props.updateData(res.data.result, props.stepConfig);
                        }
                    }, (err) => {
                        setErrorObj({ 'show': true, 'error': err.data })
                    })
                } else {
                    CandidateService.addAdditionalDetails(id, requestPayload).then((res) => {
                        if (res.status === 201 || res.status === 200) {
                            props.updateData(res.data.result, props.stepConfig);
                        }
                    }, (err) => {
                        setErrorObj({ 'show': true, 'error': err.data })
                    })
                }

            }
            else {
                props.updateData(props.wizard.professional, props.stepConfig);
            }

        }

    }));

    const onSubmitForm = (state) => {

        setEditMode(false);
        props.canProceed(true);
        setChanged(true);
    }

    const onFileChange = (e) => {
        setIsLoading(true);
        e.persist();
        e.preventDefault();
        //console.log("file changes",e);
        let formData = new FormData();
        formData.append("files", e.target.files[0])
        formData.append("mediaType", "document");
        CandidateService.upload(formData).then((res) => {
            //console.log(res)
            setIsLoading(false);
            handleOnChange({ target: { name: 'resume', value: res.data.result[0] } })
        }, (error) => {
            //console.log(error)
            setIsLoading(false);
            setErrorObj({ 'show': true, 'error': error.data })
        })

    }
    const openFileReader = () => {
        // console.log("file reader function called");
        setFileViewObj({ show: true, file: state.resume.value });
    }

    const { state, handleOnChange, handleOnSubmit, disable, setValueWithoutError } = UseForm(stateSchema, validationStateSchema, onSubmitForm);
    if (isLoading) return <Loader />;

    return (
        <div id="professional-step" className="wizard-step-wrapper">
            <div className="form-wrapper h-100">
                <div className="row no-gutters h-100 w-100">
                    <div className="container-fluid h-100 w-100 p-0">
                        <div className="row no-gutter h-100 w-100">
                            <div className="col">
                                <div className="card candidate-personal-card mt-4">
                                    <div className="card-header row no-gutters">
                                        <span className="card-title col">Professional Details</span>
                                        {editMode ?
                                            <div className="col-auto">
                                                <button className="icon-btn margin-auto btn-success" disabled={disable ? true : false} onClick={handleOnSubmit} title="Click to Save">
                                                    <DoneIcon className="save-icon"></DoneIcon>
                                                </button>
                                            </div> :
                                            <div className="col-auto">
                                                <div className="icon-btn margin-auto btn-primary" onClick={editData}>
                                                    <EditIcon className="edit-icon" ></EditIcon>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="card-body">
                                        {editMode ?

                                            <div className="row no-gutter">
                                                <div className="col-12 my-2"><b>Details :</b></div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Total Experience <sup className="required">*</sup></label>
                                                    <div className='row no-gutters'>
                                                        <div className="col-6 m-0">
                                                            <input type="number" className="form-control" id="experienceInYr" placeholder=" In Year" name="experienceInYr" value={state.experienceInYr.value} onChange={handleOnChange} />
                                                            <small className="form-text  error-text m-0" style={{ visibility: state.experienceInYr.error ? 'visible' : 'hidden' }}>{state.experienceInYr.error}</small>
                                                        </div>
                                                        <div className="col-6 m-0">
                                                            <input type="number" className="form-control" id="experienceInMnth" placeholder=" In Month" name="experienceInMnth" value={state.experienceInMnth.value} onChange={handleOnChange} />
                                                            <small className="form-text  error-text m-0" style={{ visibility: state.experienceInMnth.error ? 'visible' : 'hidden' }}>{state.experienceInMnth.error}</small>
                                                        </div>
                                                    </div>
                                                    {/* <input type="number" className="form-control" id="experienceInYr" placeholder="Experiance In Year" name="experienceInYr" value={state.experienceInYr.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.experienceInYr.error ? 'visible' : 'hidden' }}>{state.experienceInYr.error}</small> */}
                                                </div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Current CTC <sup className="required">*</sup></label>
                                                    <div className='row no-gutters'>
                                                        <div className="col-6 m-0">
                                                            <input type="number" className="form-control" id="currentCtcInLkh" placeholder=" In Lakh" name="currentCtcInLkh" value={state.currentCtcInLkh.value} onChange={handleOnChange} />
                                                            <small className="form-text  error-text m-0" style={{ visibility: state.currentCtcInLkh.error ? 'visible' : 'hidden' }}>{state.currentCtcInLkh.error}</small>
                                                        </div>
                                                        <div className="col-6 m-0">
                                                            <input type="number" className="form-control" id="currentCtcInThnd" placeholder=" In Thousand" name="currentCtcInThnd" value={state.currentCtcInThnd.value} onChange={handleOnChange} />
                                                            <small className="form-text  error-text m-0" style={{ visibility: state.currentCtcInThnd.error ? 'visible' : 'hidden' }}>{state.currentCtcInThnd.error}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Expected CTC <sup className="required">*</sup></label>
                                                    <div className='row no-gutters'>
                                                        <div className="col-6 m-0">
                                                            <input type="number" className="form-control" id="expectedCtcInLkh" placeholder=" In Lakh" name="expectedCtcInLkh" value={state.expectedCtcInLkh.value} onChange={handleOnChange} />
                                                            <small className="form-text  error-text m-0" style={{ visibility: state.expectedCtcInLkh.error ? 'visible' : 'hidden' }}>{state.expectedCtcInLkh.error}</small>
                                                        </div>
                                                        <div className="col-6 m-0">
                                                            <input type="number" className="form-control" id="expectedCtcInThnd" placeholder=" In Thousand" name="expectedCtcInThnd" value={state.expectedCtcInThnd.value} onChange={handleOnChange} />
                                                            <small className="form-text  error-text m-0" style={{ visibility: state.expectedCtcInThnd.error ? 'visible' : 'hidden' }}>{state.expectedCtcInThnd.error}</small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Preferred Locations <sup className="required">*</sup></label>
                                                    <Autocomplete
                                                        name="preferred-locations"
                                                        placeholder="Preferred Locations"
                                                        defaultOptions
                                                        isMulti={true}
                                                        isClearable={true}
                                                        options={cityList}
                                                        value={selectedCity && selectedCity.length > 0 ?
                                                            selectedCity.map(course => {
                                                                return { name: course.name, id: course.id }
                                                            }) : null}
                                                        filterOption={customFilter}
                                                        isLoading={cityLoading}
                                                        getOptionLabel={(option) => {
                                                            return (
                                                                <div className="text col p-1 text-truncate">
                                                                    <div>{option.name}</div>
                                                                </div>
                                                            );
                                                        }}
                                                        getOptionValue={(option) => {
                                                            return option.id;
                                                        }}
                                                        onChange={cityChange}
                                                        onMenuScrollToBottom={paginateCityList}
                                                        onInputChange={onCityInputChange}
                                                    />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.preferredLocations.error ? 'visible' : 'hidden' }}>{state.preferredLocations.error}</small>
                                                </div>

                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Category <sup className="required">*</sup></label>
                                                    <Autocomplete
                                                        name="category"
                                                        placeholder="Select Category"
                                                        defaultOptions
                                                        isClearable={true}
                                                        filterOption={customFilter}
                                                        options={categoryList}
                                                        value={state.category.value ? state.category.value : null}
                                                        getOptionLabel={(option) => {
                                                            return (
                                                                <div className="row autocomplete-item no-gutters">
                                                                    <div className="text col p-1 text-truncate">
                                                                        <div>{option.name}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                        getOptionValue={(option) => {
                                                            return option.id;
                                                        }}
                                                        onChange={categoryChanges}
                                                    />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.category.error ? 'visible' : 'hidden' }}>{state.category.error}</small>
                                                </div>


                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Role <sup className="required">*</sup></label>
                                                    <Autocomplete
                                                        name="role"
                                                        placeholder="Select Role"
                                                        defaultOptions
                                                        isClearable={true}
                                                        isDisabled={state.category.value && state.category.value.id ? false : true}
                                                        filterOption={customFilter}
                                                        options={roleList}
                                                        value={state.role.value ? state.role.value : null}
                                                        getOptionLabel={(option) => {
                                                            return (
                                                                <div className="row autocomplete-item no-gutters">
                                                                    <div className="text col p-1 text-truncate">
                                                                        <div>{option.name}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                        getOptionValue={(option) => {
                                                            return option.id;
                                                        }}
                                                        onChange={roleChanges}
                                                    />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.role.error ? 'visible' : 'hidden' }}>{state.role.error}</small>
                                                </div>

                                                <div className="col-12 my-2"><b>Current Address :</b></div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">State <sup className="required">*</sup></label>
                                                    <select className="form-control" name="state" id="state" onChange={onStateChange} value={state.state.value}>
                                                        <option value="">Select State</option>
                                                        {stateList ? stateList.map((state, index) => { return (<option key={index} value={state.id}>{state.name}</option>) }) : ''}
                                                    </select>
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.state.error ? 'visible' : 'hidden' }}>{state.state.error}</small>
                                                </div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">City <sup className="required">*</sup></label>
                                                    <select className="form-control" name="city" id="city" onChange={handleOnChange} value={state.city.value} disabled={state.state.value ? false : true}>
                                                        <option value="">Select City</option>
                                                        {cityLists ? cityLists.map((city, index) => { return (<option key={index} value={city.id}>{city.name}</option>) }) : ''}
                                                    </select>
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.city.error ? 'visible' : 'hidden' }}>{state.city.error}</small>
                                                </div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Town </label>
                                                    <input type="text" className="form-control" placeholder="Town" id="town" name="town" value={state.town.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.town.error ? 'visible' : 'hidden' }}>{state.town.error}</small>
                                                </div>

                                                <div className="col-12 my-2"><b>Resume :</b></div>
                                                <div className="form-group m-0 col-6">
                                                    <label className="label-sm w-100">Upload Resume <sup className="required">*</sup></label>
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="fileUpload btn btn-secondary cs-upload-1 m-0 col-auto">
                                                            <span>Browse</span>
                                                            <input type="file" className="upload" name="resume" id="resume" onChange={onFileChange} accept=".doc,.docx,.rtf,.pdf" />
                                                        </div>
                                                        <div className="col file-name">
                                                            <div> {state.resume.value ? state.resume.value.originalName : ''} </div>
                                                        </div>
                                                    </div>

                                                    <small id="logoHelp" className="form-text text-muted">Max size of file is 5MB.</small>
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.resume.error ? 'visible' : 'hidden' }}>{state.resume.error}</small>

                                                </div>
                                            </div>
                                            :
                                            <div className="row no-gutters">
                                                <div className="col-12 my-2"><b>Details :</b></div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-truncate fw-500" title="Total Experience ">Total Experience </div>
                                                    <div className="col-7 text-wrap">{`${state.experienceInYr.value || 0} Year ${state.experienceInMnth.value || 0} Month`}</div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-truncate fw-500" title="Current CTC ">Current CTC </div>
                                                    <div className="col-7 ttext-wrap">{`${state.currentCtcInLkh.value || 0} Lakh ${state.currentCtcInThnd.value || 0} Thousand`}</div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-truncate fw-500" title="Expected CTC">Expected CTC </div>
                                                    <div className="col-7 text-wrap">{`${state.expectedCtcInLkh.value || 0} Lakh ${state.expectedCtcInThnd.value || 0} Thousand`}</div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-wrap fw-500">Preferred Locations</div>
                                                    <div className="col-7 text-wrap">
                                                        {state.preferredLocations.value && state.preferredLocations.value.length > 0 ?
                                                            state.preferredLocations.value.map((item, index) => {
                                                                return <span className="badge badge-primary mx-1" key={index}>{item.name}</span>
                                                            }) : "NA"
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-truncate fw-500">Category</div>
                                                    <div className="col-7 text-wrap">{state.category.value ? state.category.value.name : "NA"}</div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-truncate fw-500">Role</div>
                                                    <div className="col-7 text-wrap">{state.role.value ? state.role.value.name : "NA"}</div>
                                                </div>
                                                <div className="col-12 my-2"><b>Current Address :</b></div>
                                                <div className="col-6 row p-2">
                                                    <div className="col-5 text-wrap fw-500">State</div>
                                                    <div className="col-7 text-wrap">{state.state.value && stateList.length > 0 ? stateList.filter(item => item.id === state.state.value)[0].name : 'NA'}</div>
                                                </div>
                                                <div className="col-6 row p-2">
                                                    <div className="col-5 text-wrap fw-500">City</div>
                                                    <div className="col-7 text-wrap">{state.city.value && cityLists.length > 0 ? cityLists.filter(item => item.id === state.city.value)[0].name : 'NA'}</div>
                                                </div>
                                                <div className="col-6 row p-2">
                                                    <div className="col-5 text-wrap fw-500">Town</div>
                                                    <div className="col-7 text-wrap">{state.town.value || 'NA'}</div>
                                                </div>
                                                <div className="col-12 my-2"><b>Resume :</b></div>
                                                <div className="col-12  row p-2">
                                                    <div className="col text-wrap row no-gutters resume">
                                                        {state.resume.value && state.resume.value.id ?
                                                            <div className="col-12 row no-gutters p-2 align-items-center">
                                                                <p className="col-auto extension p-3">{`.${state.resume.value.originalName.split('.')[1]}`}</p>
                                                                <div className="col row no-gutters p-3 file-name m-0">
                                                                    <p className="col-12 text-truncate m-0 ">{state.resume.value.originalName.split('.')[0]}</p>
                                                                    <p className="col-12 text-truncate m-0">{state.resume.value.size}</p>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <a type="button" className="btn file-view-btn p-3 " onClick={openFileReader}>
                                                                        <VisibilityIcon></VisibilityIcon>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            : "NA"
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorModal data={errorObj} onConfirmAction={() => setErrorObj({ show: false, error: {} })}></ErrorModal>
            <FileViewerModal data={fileViewObj} onCancel={() => setFileViewObj({ show: false, file: {} })}></FileViewerModal>
        </div>
    )
})

export default ProfessionalStep;