import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import CandidateService from "../../../service/CandidateService";
import { useParams, Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import Autocomplete from "react-select";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import UseForm from "../../../service/FormService";
import $ from "jquery";
import ErrorModal from "../../../component/modal/ErrorModal";
import moment from "moment";

const initialSchema = {
  employer: { value: "", error: "" },
  category: { value: "", error: "" },
  role: { value: "", error: "" },
  designation: { value: "", error: "" },
  isCurrentlyWorking: { value: false, error: "" },
  joinedOn: { value: new Date(), error: "" },
  seperatedOn: { value: new Date(), error: "" },
  annualCtcInLkh: { value: "", error: "" },
  annualCtcInThnd: { value: "", error: "" },
  responsibility: { value: "", error: "" },
  state: { value: "", error: "" },
  city: { value: "", error: "" },
  town: { value: "", error: "" },
};

const validationStateSchema = {
  employer: { required: true },
  category: { required: true },
  role: { required: true },
  designation: { required: true },
  isCurrentlyWorking: { required: false },
  joinedOn: { required: true },
  seperatedOn: { required: false },
  annualCtcInLkh: { required: true },
  annualCtcInThnd: { required: true },
  responsibility: { required: true },
  state: { required: true },
  city: { required: true },
  town: { required: false },
};

const CareerForm = (props) => {
  const [stateSchema, setStateSchema] = useState(initialSchema);
  const [editMode, setEditMode] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [currentlyWorking, setCurrentlyWorking] = useState(false);

  useEffect(() => {
    // console.log("QualificationForm called 1", props);
    if (
      props.rowIndex === props.totalRow - 1 &&
      Object.keys(props.data).length === 0
    ) {
      setEditMode(true);
      props.restrictMoveToNext(false);
    }

    if (props.data && Object.keys(props.data).length > 0) {
      upDateInitialData();
    }
  }, []);

  const upDateInitialData = () => {
    const data = props.data;
    let schema = $.extend(true, {}, stateSchema);
    schema.employer.value = data.employer;
    schema.category.value = data.category;
    schema.role.value = data.role;
    schema.designation.value = data.designation;
    schema.joinedOn.value = data.joinedOn ? new Date(data.joinedOn) : null;
    schema.seperatedOn.value = data.seperatedOn
      ? new Date(data.seperatedOn)
      : null;
    schema.isCurrentlyWorking.value = data.isCurrentlyWorking;
    setCurrentlyWorking(data.isCurrentlyWorking);
    schema.annualCtcInLkh.value = data.annualCtcInLkh;
    schema.annualCtcInThnd.value = data.annualCtcInThnd;
    schema.responsibility.value = data.responsibility;
    schema.state.value = data.state;
    schema.city.value = data.city;
    schema.town.value = data.town;
    setStateSchema(schema);
  };

  const editData = () => {
    setEditMode(true);
    props.restrictMoveToNext(false);
  };

  const categoryChanges = (data) => {
    handleOnChange({
      target: {
        name: "category",
        value: data ? { id: data.id, name: data.name } : "",
      },
    });
    handleOnChange({ target: { name: "role", value: "" } });

    if (data && data.id) getRoleList(data.id);
  };

  const getRoleList = async (categoryId, param) => {
    try {
      const response = await CandidateService.getRoleList(categoryId, param);
      setRoleList(response.data.result);
    } catch (err) {
      // console.log(err);
    }
  };
  const roleChanges = (data) => {
    handleOnChange({
      target: {
        name: "role",
        value: data ? { id: data.id, name: data.name } : "",
      },
    });
  };

  const stateChanges = (data) => {
    handleOnChange({
      target: {
        name: "state",
        value: data ? { id: data.id, name: data.name } : "",
      },
    });
    handleOnChange({ target: { name: "city", value: "" } });
    if (data && data.id) getCityList(data.id);
  };

  const getCityList = async (courseId) => {
    try {
      const response = await CandidateService.getCityList(courseId);
      setCityList(response.data.result);
    } catch (err) {
      //console.log(err);
    }
  };

  const cityChanges = (data) => {
    handleOnChange({
      target: {
        name: "city",
        value: data ? { id: data.id, name: data.name } : "",
      },
    });
  };

  const customFilter = (option, inputValue) => {
    if (option.data.name && inputValue) {
      return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }
    return true;
  };

  const joinedOnChange = (data) => {
    handleOnChange({ target: { name: "joinedOn", value: data ? data : "" } });
  };

  const seperatedOnChange = (data) => {
    handleOnChange({
      target: { name: "seperatedOn", value: data ? data : "" },
    });
  };

  const currentlyWorkingChanges = (data) => {
    data && seperatedOnChange();
    handleOnChange({
      target: { name: "isCurrentlyWorking", value: data ? data : "" },
    });
    setCurrentlyWorking(data);
  };

  const onSubmitForm = (state) => {
    //console.log("on submit is called",state);
    setEditMode(false);
    const requestData = {};

    requestData.employer = state.employer.value;
    requestData.category = state.category.value;
    requestData.role = state.role.value;
    requestData.joinedOn = state.joinedOn.value;
    requestData.seperatedOn = state.seperatedOn.value;
    requestData.isCurrentlyWorking = state.isCurrentlyWorking.value;
    requestData.designation = state.designation.value;
    requestData.state = state.state.value;
    requestData.city = state.city.value;
    requestData.town = state.town.value;
    requestData.responsibility = state.responsibility.value;
    requestData.annualCtcInLkh = state.annualCtcInLkh.value;
    requestData.annualCtcInThnd = state.annualCtcInThnd.value;
    requestData.id = props.data.id || "";

    props.updateData(requestData, props.rowIndex);
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div className="qualification-form">
      <div className="form-wrapper">
        <div className="row h-100 w-100">
          <div className="container-fluid h-100 w-100 p-0">
            <div className="row h-100 w-100">
              <div className="col-10">
                <div className="card candidate-qualification-card">
                  <div className="card-header row no-gutters">
                    <span className="count col-auto">{props.rowIndex + 1}</span>
                    <span className="card-title col">Employer Details</span>

                    {editMode ? (
                      <div className="col-auto">
                        <button
                          className="icon-btn margin-auto btn-success"
                          disabled={disable ? true : false}
                          onClick={handleOnSubmit}
                          title="Click to Save"
                        >
                          <DoneIcon className="save-icon"></DoneIcon>
                        </button>
                      </div>
                    ) : (
                      <div className="col-auto">
                        <div
                          className="icon-btn margin-auto btn-primary"
                          onClick={editData}
                        >
                          <EditIcon className="edit-icon"></EditIcon>
                        </div>
                      </div>
                    )}
                    {props.totalRow > 1 ? (
                      <div className="col-auto">
                        <div
                          className="icon-btn margin-auto btn-danger"
                          onClick={() => {
                            props.onRemove(props.rowIndex);
                          }}
                        >
                          <DeleteForeverIcon className="delete-icon"></DeleteForeverIcon>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {editMode ? (
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Employer Name <sup className="required">*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="employer"
                            id="employer"
                            onChange={handleOnChange}
                            value={state.employer.value}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.employer.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.employer.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Category <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="category"
                            placeholder="Select Category"
                            defaultOptions
                            isClearable={true}
                            filterOption={customFilter}
                            options={props.categoryList}
                            value={
                              state.category.value ? state.category.value : null
                            }
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={categoryChanges}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.category.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.category.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Role <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="role"
                            placeholder="Select Role"
                            defaultOptions
                            isClearable={true}
                            isDisabled={
                              state.category.value && state.category.value.id
                                ? false
                                : true
                            }
                            filterOption={customFilter}
                            options={roleList}
                            value={state.role.value ? state.role.value : null}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={roleChanges}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.role.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.role.error}
                          </small>
                        </div>
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Designation <sup className="required">*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="designation"
                            id="designation"
                            onChange={handleOnChange}
                            value={state.designation.value}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.designation.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.designation.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <div className="margin-top-auto highest-qualification ml-3">
                            <label className="label-sm mr-4 ">
                              is Currently Working
                            </label>
                            <div
                              className="switch"
                              onClick={() =>
                                currentlyWorkingChanges(!currentlyWorking)
                              }
                            >
                              {currentlyWorking ? (
                                <input
                                  type="checkbox"
                                  name="isHotJobTrue"
                                  id="isHotJob"
                                  value={currentlyWorking}
                                  checked={currentlyWorking}
                                  onChange={() => { }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  name="isHotJobFalse"
                                  id="isHotJob"
                                  value={currentlyWorking}
                                  checked={currentlyWorking}
                                  onChange={() => { }}
                                />
                              )}
                              <span className="slider round"></span>
                            </div>
                            <small
                              className="form-text  error-text m-0"
                              style={{
                                visibility: state.isCurrentlyWorking.error
                                  ? "visible"
                                  : "hidden",
                              }}
                            >
                              {state.isCurrentlyWorking.error}
                            </small>
                          </div>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Joined Date <sup className="required">*</sup>
                          </label>
                          <DatePicker
                            className="form-control w-100"
                            selected={state.joinedOn.value}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={joinedOnChange}
                            autoComplete="off"
                          ></DatePicker>
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.joinedOn.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.joinedOn.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Relieving Date{" "}
                            {!currentlyWorking ? (
                              <sup className="required">*</sup>
                            ) : (
                              ""
                            )}
                          </label>
                          <DatePicker
                            className="form-control w-100"
                            selected={state.seperatedOn.value}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            disabled={currentlyWorking}
                            onChange={seperatedOnChange}
                            autoComplete="off"
                          ></DatePicker>
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.seperatedOn.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.seperatedOn.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            State <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="State"
                            placeholder="Select State"
                            defaultOptions
                            isClearable={true}
                            filterOption={customFilter}
                            options={props.stateList}
                            value={state.state.value ? state.state.value : null}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={stateChanges}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.state.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.state.error}
                          </small>
                        </div>
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            City <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="city"
                            placeholder="Select City"
                            defaultOptions
                            isClearable={true}
                            isDisabled={
                              state.state.value && state.state.value.id
                                ? false
                                : true
                            }
                            filterOption={customFilter}
                            options={cityList}
                            value={state.city.value ? state.city.value : null}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={cityChanges}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.city.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.city.error}
                          </small>
                        </div>
                        <div className="col-6 form-group m-0">
                          <label className="label-sm">
                            Town <sup className="required">*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="town"
                            id="town"
                            disabled={state.city.value ? false : true}
                            onChange={handleOnChange}
                            value={state.town.value}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.town.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.town.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">Annual CTC <sup className="required">*</sup></label>
                          <div className='row no-gutters'>
                            <div className="col-6 m-0">
                              <input type="number" className="form-control" id="annualCtcInLkh" placeholder=" In Lakh" name="annualCtcInLkh" value={state.annualCtcInLkh.value} onChange={handleOnChange} />
                              <small className="form-text  error-text m-0" style={{ visibility: state.annualCtcInLkh.error ? 'visible' : 'hidden' }}>{state.annualCtcInLkh.error}</small>
                            </div>
                            <div className="col-6 m-0">
                              <input type="number" className="form-control" id="annualCtcInThnd" placeholder=" In Thousand" name="annualCtcInThnd" value={state.annualCtcInThnd.value} onChange={handleOnChange} />
                              <small className="form-text  error-text m-0" style={{ visibility: state.annualCtcInThnd.error ? 'visible' : 'hidden' }}>{state.annualCtcInThnd.error}</small>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-6 form-group m-0">
                          <label className="label-sm">
                            Annual CTC {" "}
                            <sup className="required">*</sup>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="annualCtcInLkh"
                            id="annualCtcInLkh"
                            onChange={handleOnChange}
                            value={state.annualCtcInLkh.value}
                          />
                          <input
                            type="number"
                            className="form-control"
                            name="annualCtcInThnd"
                            id="annualCtcInThnd"
                            onChange={handleOnChange}
                            value={state.annualCtcInThnd.value}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.annualCtc.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.annualCtc.error}
                          </small>
                        </div> */}

                        <div className="col-12 form-group m-0">
                          <label className="label-sm">
                            Responsibility<sup className="required">*</sup>
                          </label>
                          <textarea
                            className="form-control"
                            name="responsibility"
                            id="responsibility"
                            onChange={handleOnChange}
                            rows="4"
                            value={state.responsibility.value}
                          ></textarea>
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.responsibility.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.responsibility.error}
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">Employer</div>
                          <div className="col-7 text-wrap">
                            {state.employer.value}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">Category</div>
                          <div className="col-7 text-wrap">
                            {state.category.value && state.category.value.name
                              ? state.category.value.name
                              : "NA"}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">Role</div>
                          <div className="col-7 ttext-wrap">
                            {state.role.value && state.role.value.name
                              ? state.role.value.name
                              : "NA"}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Designation
                          </div>
                          <div className="col-7 text-wrap">
                            {state.designation.value}
                          </div>
                        </div>
                        <div className="col-6 row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Joined Date
                          </div>
                          <div className="col-7 text-wrap">
                            <Moment
                              date={state.joinedOn.value || null}
                              format={"MM-DD-YYYY"}
                            ></Moment>
                          </div>
                        </div>
                        <div className="col-6 row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Relieving Date
                          </div>
                          <div className="col-7 text-wrap">
                            {state.seperatedOn.value ? (
                              <Moment
                                date={state.seperatedOn.value}
                                format={"MM-DD-YYYY"}
                              ></Moment>
                            ) : (
                              "NA"
                            )}
                          </div>
                        </div>
                        <div className="col-6 row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Currently Working
                          </div>
                          <div className="col-7 text-wrap">
                            {state.isCurrentlyWorking.value ? "YES" : "NO"}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Annual CTC
                          </div>
                          <div className="col-7 text-wrap">
                            {isNaN(state.annualCtcInLkh.value)
                              ? "NA"
                              : state.annualCtcInLkh.value + ' Lakh ' + state.annualCtcInThnd.value + ' Thousand'}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">State</div>
                          <div className="col-7 text-wrap">
                            {state.state.value && state.state.value.name
                              ? state.state.value.name
                              : "NA"}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">City</div>
                          <div className="col-7 text-wrap">
                            {state.city.value && state.city.value.name
                              ? state.city.value.name
                              : "NA"}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">Town</div>
                          <div className="col-7 text-wrap">
                            {state.town.value}
                          </div>
                        </div>
                        <div className="col-12  row p-2">
                          <div className="col-12 text-wrap fw-500">
                            Responsibility
                          </div>
                          <div className="col-12 text-wrap">
                            {state.responsibility.value}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const initialData = { valid: false, data: {} };

const initData = (props) => {
  let data = [];
  let stepDate = $.extend(true, [], props.wizard.careers);
  stepDate.forEach((item, index) => {
    let itemData = item;
    itemData.joinedOn = itemData.joinedOn ? new Date(itemData.joinedOn) : null;
    itemData.passoutYear = itemData.seperatedOn
      ? new Date(itemData.seperatedOn)
      : null;
    itemData.state = itemData.jobLocation.state;
    itemData.city = itemData.jobLocation.city;
    itemData.town = itemData.jobLocation.town;
    delete itemData.jobLocation;
    data.push({ valid: true, data: itemData });
  });
  return data;
};

const CarrierStep = forwardRef((props, ref) => {
  const { id } = useParams();
  const [stepData, setStepData] = useState(
    props.wizard.careers && props.wizard.careers.length > 0
      ? initData(props)
      : [$.extend(true, {}, initialData)]
  );
  const [categoryList, setCategoryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [errorObj, setErrorObj] = useState({ show: false, error: {} });
  const [isChanged, setChanged] = useState(false);

  useEffect(() => {
    getCategoryList();
    getStateList();
    setLoading(false);
    setChanged(false);
  }, []);

  useEffect(() => {
    // console.log("use effect stepData", stepData);
    validateStep();
  }, [stepData]);

  const getCategoryList = async () => {
    try {
      const response = await CandidateService.getCategoryList();
      setCategoryList(response.data.result);
    } catch (err) {
      setLoading(false);
    }
  };

  const getStateList = async () => {
    try {
      const response = await CandidateService.getStateList();
      setStateList(response.data.result);
    } catch (err) {
      setLoading(false);
    }
  };

  const AddRow = () => {
    const steps = $.extend(true, [], stepData);
    const newItem = $.extend(true, {}, initialData);
    steps.push(newItem);
    setStepData(steps);
  };

  const removeRow = (index) => {
    const data = $.extend(true, [], stepData);
    data.splice(index, 1);
    setStepData(data);
  };

  const updateData = (rowData, index) => {
    setChanged(true);
    const steps = $.extend(true, [], stepData);
    steps[index].data = $.extend(true, {}, rowData);
    steps[index].valid = true;
    setStepData(steps);
  };
  const validateStep = () => {
    let validCount = 0;
    stepData.forEach((item) => {
      if (item.valid) validCount++;
    });
    const isValid = validCount === stepData.length ? true : false;
    props.canProceed(isValid);
  };
  const getDateFormat = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  useImperativeHandle(ref, () => ({
    submit() {
      //console.log("Step1 submit")
      if (isChanged) {
        const requestPayload = [];
        let isUpdate = false;
        stepData.forEach((item) => {
          if (item.data.id) {
            isUpdate = true;
          }
          const requestData = {};

          requestData.employer = item.data.employer;
          requestData.categoryId = item.data.category.id;
          requestData.roleId = item.data.role.id;
          requestData.joinedOn = item.data.joinedOn
            ? getDateFormat(item.data.joinedOn)
            : null;
          requestData.seperatedOn = item.data.seperatedOn
            ? getDateFormat(item.data.seperatedOn)
            : null;
          requestData.isCurrentlyWorking = item.data.isCurrentlyWorking;
          requestData.designation = item.data.designation;
          requestData.jobLocation = {
            state: item.data.state.id,
            city: item.data.city.id,
            town: item.data.town,
          };
          requestData.responsibility = item.data.responsibility;
          requestData.annualCtcInLkh = item.data.annualCtcInLkh;
          requestData.annualCtcInThnd = item.data.annualCtcInThnd;
          if (isUpdate) {
            requestData.id = item.data.id;
          }
          requestPayload.push(requestData);
        });
        if (isUpdate) {
          CandidateService.updateCarrerDetails(id, requestPayload).then(
            (res) => {
              if (res.status === 201 || res.status === 200) {
                props.updateData(res.data.result, props.stepConfig);
              }
            },
            (err) => {
              setErrorObj({ show: true, error: err.data });
            }
          );
        } else {
          CandidateService.addCarrerDetails(id, requestPayload).then(
            (res) => {
              if (res.status === 201 || res.status === 200) {
                props.updateData(res.data.result, props.stepConfig);
              }
            },
            (err) => {
              setErrorObj({ show: true, error: err.data });
            }
          );
        }
      } else {
        props.updateData(props.wizard.careers, props.stepConfig);
      }
    },
  }));
  return (
    <div id="professional-step" className="wizard-step-wrapper">
      <div className="step-container">
        <div className="row mb-4">
          <p className="step-info-text m-0 col">
            User need to enterd their professional details here .
          </p>
          <div className="col-auto">
            <button className="btn btn-primary btn-sm" onClick={AddRow}>
              + Add New Row
            </button>
          </div>
        </div>

        <div className="step-content">
          {stepData.map((item, index) => {
            return (
              <CareerForm
                key={index}
                data={item.data}
                updateData={updateData}
                onRemove={removeRow}
                rowIndex={index}
                totalRow={stepData.length}
                categoryList={categoryList}
                stateList={stateList}
                restrictMoveToNext={props.canProceed}
              ></CareerForm>
            );
          })}
        </div>
      </div>
      <ErrorModal
        data={errorObj}
        onConfirmAction={() => setErrorObj({ show: false, error: {} })}
      ></ErrorModal>
    </div>
  );
});

export default CarrierStep;
