import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import CandidateService from "../../../service/CandidateService";
import { useParams, Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import Autocomplete from "react-select";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import UseForm from "../../../service/FormService";
import $ from "jquery";
import ErrorModal from "../../../component/modal/ErrorModal";

const initialSchema = {
  lineOne: { value: "", error: "" },
  addressType: { value: "", error: "" },
  lineTwo: { value: "", error: "" },
  isCurrentAddress: { value: false, error: "" },
  state: { value: "", error: "" },
  city: { value: "", error: "" },
  town: { value: "", error: "" },
};

const validationStateSchema = {
  lineOne: { required: true },
  addressType: { required: true },
  lineTwo: { required: true },
  isCurrentAddress: { required: false },
  state: { required: true },
  city: { required: true },
  town: { required: false },
};

const AddressForm = (props) => {
  const [stateSchema, setStateSchema] = useState(initialSchema);
  const [editMode, setEditMode] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(false);

  useEffect(() => {
    if (
      props.rowIndex === props.totalRow - 1 &&
      Object.keys(props.data).length === 0
    ) {
      setEditMode(true);
      props.restrictMoveToNext(false);
    }

    if (props.data && Object.keys(props.data).length > 0) {
      upDateInitialData();
    }
  }, []);

  const upDateInitialData = () => {
    const data = props.data;
    let schema = $.extend(true, {}, stateSchema);
    Object.keys(schema).forEach((key) => {
      if (data.hasOwnProperty(key)) {
        schema[key].value = data[key];
      }
    });
    setCurrentAddress(data.isCurrentAddress);
    setStateSchema(schema);
  };

  const editData = () => {
    setEditMode(true);
    props.restrictMoveToNext(false);
  };

  const stateChanges = (data) => {
    handleOnChange({
      target: {
        name: "state",
        value: data ? { id: data.id, name: data.name } : "",
      },
    });
    setValueWithoutError({ key: "city", value: "" });
    setValueWithoutError({ key: "town", value: "" });
    if (data && data.id) getCityList(data.id);
  };

  const getCityList = async (courseId) => {
    try {
      const response = await CandidateService.getCityList(courseId);
      setCityList(response.data.result);
    } catch (err) {
      //console.log(err);
    }
  };

  const cityChanges = (data) => {
    handleOnChange({
      target: {
        name: "city",
        value: data ? { id: data.id, name: data.name } : "",
      },
    });
    setValueWithoutError({ key: "town", value: "" });
  };

  const customFilter = (option, inputValue) => {
    if (option.data.name && inputValue) {
      return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }
    return true;
  };

  const currentAddressChanges = (data) => {
    handleOnChange({
      target: { name: "isCurrentAddress", value: data ? data : "" },
    });
    setCurrentAddress(data);
  };

  const onSubmitForm = (state) => {
    //console.log("on submit is called",state);
    setEditMode(false);
    const requestData = {};

    requestData.lineOne = state.lineOne.value;
    requestData.lineTwo = state.lineTwo.value;
    requestData.addressType = state.addressType.value;
    requestData.isCurrentAddress = state.isCurrentAddress.value;
    requestData.state = state.state.value;
    requestData.city = state.city.value;
    requestData.town = state.town.value;
    requestData.id = props.data.id || "";

    props.updateData(requestData, props.rowIndex);
  };

  const {
    state,
    handleOnChange,
    handleOnSubmit,
    disable,
    setValueWithoutError,
  } = UseForm(stateSchema, validationStateSchema, onSubmitForm);

  return (
    <div className="qualification-form">
      <div className="form-wrapper">
        <div className="row h-100 w-100">
          <div className="container-fluid h-100 w-100 p-0">
            <div className="row h-100 w-100">
              <div className="col-10">
                <div className="card candidate-qualification-card">
                  <div className="card-header row no-gutters">
                    <span className="count col-auto">{props.rowIndex + 1}</span>
                    <span className="card-title col">Address Details</span>

                    {editMode ? (
                      <div className="col-auto">
                        <button
                          className="icon-btn margin-auto btn-success"
                          disabled={disable ? true : false}
                          onClick={handleOnSubmit}
                          title="Click to Save"
                        >
                          <DoneIcon className="save-icon"></DoneIcon>
                        </button>
                      </div>
                    ) : (
                      <div className="col-auto">
                        <div
                          className="icon-btn margin-auto btn-primary"
                          onClick={editData}
                        >
                          <EditIcon className="edit-icon"></EditIcon>
                        </div>
                      </div>
                    )}
                    {props.totalRow > 1 ? (
                      <div className="col-auto">
                        <div
                          className="icon-btn margin-auto btn-danger"
                          onClick={() => {
                            props.onRemove(props.rowIndex);
                          }}
                        >
                          <DeleteForeverIcon className="delete-icon"></DeleteForeverIcon>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {editMode ? (
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Address Type<sup className="required">*</sup>
                          </label>
                          <select
                            className="form-control"
                            name="addressType"
                            id="addressType"
                            onChange={handleOnChange}
                            value={state.addressType.value}
                          >
                            <option value="">Select Address Type</option>
                            <option value="PERMANENT">PERMANENT</option>
                            <option value="TEMPORARY">TEMPORARY</option>
                          </select>
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.addressType.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.addressType.error}
                          </small>
                        </div>
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Address Line 1<sup className="required">*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lineOne"
                            id="lineOne"
                            onChange={handleOnChange}
                            value={state.lineOne.value}
                            placeholder="HOUSE, FLAT, BLOCK NO, APARTMENT"
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.lineOne.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.lineOne.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Address Line 2<sup className="required">*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lineTwo"
                            id="lineTwo"
                            onChange={handleOnChange}
                            value={state.lineTwo.value}
                            placeholder="STREET,NEAR TO LOCATION,PINCODE"
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.lineTwo.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.lineTwo.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <div className="margin-top-auto highest-qualification ml-3">
                            <label className="label-sm mr-4 ">
                              is Current Address
                            </label>
                            <div
                              className="switch"
                              onClick={() =>
                                currentAddressChanges(!currentAddress)
                              }
                            >
                              {currentAddress ? (
                                <input
                                  type="checkbox"
                                  name="isHotJobTrue"
                                  id="isHotJob"
                                  value={currentAddress}
                                  checked={currentAddress}
                                  onChange={() => { }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  name="isHotJobFalse"
                                  id="isHotJob"
                                  value={currentAddress}
                                  checked={currentAddress}
                                  onChange={() => { }}
                                />
                              )}
                              <span className="slider round"></span>
                            </div>
                            <small
                              className="form-text  error-text m-0"
                              style={{
                                visibility: state.isCurrentAddress.error
                                  ? "visible"
                                  : "hidden",
                              }}
                            >
                              {state.isCurrentAddress.error}
                            </small>
                          </div>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            State <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="State"
                            placeholder="Select State"
                            defaultOptions
                            isClearable={true}
                            filterOption={customFilter}
                            options={props.stateList}
                            value={state.state.value ? state.state.value : null}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={stateChanges}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.state.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.state.error}
                          </small>
                        </div>
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            City <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="city"
                            placeholder="Select City"
                            defaultOptions
                            isClearable={true}
                            isDisabled={
                              state.state.value && state.state.value.id
                                ? false
                                : true
                            }
                            filterOption={customFilter}
                            options={cityList}
                            value={state.city.value ? state.city.value : null}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={cityChanges}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.state.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.state.error}
                          </small>
                        </div>
                        <div className="col-6 form-group m-0">
                          <label className="label-sm">
                            Town
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="town"
                            id="town"
                            disabled={state.city.value ? false : true}
                            onChange={handleOnChange}
                            value={state.town.value}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.town.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.town.error}
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Address Type
                          </div>
                          <div className="col-7 text-wrap">
                            {state.addressType.value}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Address Line 1
                          </div>
                          <div className="col-7 text-wrap">
                            {state.lineOne.value}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Address Line 2
                          </div>
                          <div className="col-7 ttext-wrap">
                            {state.lineTwo.value}
                          </div>
                        </div>
                        <div className="col-6 row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Current Address
                          </div>
                          <div className="col-7 text-wrap">
                            {state.isCurrentAddress.value ? "YES" : "NO"}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">State</div>
                          <div className="col-7 text-wrap">
                            {state.state.value && state.state.value.name
                              ? state.state.value.name
                              : "NA"}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">City</div>
                          <div className="col-7 text-wrap">
                            {state.city.value && state.city.value.name
                              ? state.city.value.name
                              : "NA"}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">Town</div>
                          <div className="col-7 text-wrap">
                            {state.town.value}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const initialData = { valid: false, data: {} };

const initData = (props) => {
  let data = [];
  let stepDate = $.extend(true, [], props.wizard.addresses);
  stepDate.forEach((item, index) => {
    let itemData = item;
    data.push({ valid: true, data: itemData });
  });
  return data;
};

const AddressStep = forwardRef((props, ref) => {
  const { id, userType } = useParams();
  const history = useHistory();
  const [stepData, setStepData] = useState(
    props.wizard.addresses && props.wizard.addresses.length > 0
      ? initData(props)
      : [$.extend(true, {}, initialData)]
  );
  const [stateList, setStateList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [errorObj, setErrorObj] = useState({ show: false, error: {} });
  const [isChanged, setChanged] = useState(false);

  useEffect(() => {
    getStateList();
    setLoading(false);
    setChanged(false);
  }, []);

  useEffect(() => {
    //console.log("use effect stepData",stepData);
    validateStep();
  }, [stepData]);

  const getStateList = async () => {
    try {
      const response = await CandidateService.getStateList("active");
      setStateList(response.data.result);
    } catch (err) {
      setLoading(false);
    }
  };

  const AddRow = () => {
    const steps = $.extend(true, [], stepData);
    const newItem = $.extend(true, {}, initialData);
    steps.push(newItem);
    setStepData(steps);
  };

  const removeRow = (index) => {
    const data = $.extend(true, [], stepData);
    data.splice(index, 1);
    setStepData(data);
  };

  const updateData = (rowData, index) => {
    setChanged(true);
    const steps = $.extend(true, [], stepData);
    steps[index].data = $.extend(true, {}, rowData);
    steps[index].valid = true;
    setStepData(steps);
  };
  const validateStep = () => {
    let validCount = 0;
    stepData.forEach((item) => {
      if (item.valid) validCount++;
    });
    const isValid = validCount === stepData.length ? true : false;
    props.canProceed(isValid);
  };

  useImperativeHandle(ref, () => ({
    submit() {
      //console.log("Step1 submit")
      if (isChanged) {
        const requestPayload = [];
        let isUpdate = false;
        stepData.forEach((item) => {
          if (item.data.id) {
            isUpdate = true;
          }
          const requestData = {};

          requestData.lineOne = item.data.lineOne;
          requestData.lineTwo = item.data.lineTwo;
          requestData.addressType = item.data.addressType;
          requestData.isCurrentAddress = item.data.isCurrentAddress;
          requestData.state = item.data.state.id;
          requestData.city = item.data.city.id;
          requestData.town = item.data.town;
          if (isUpdate) {
            requestData.id = item.data.id;
          }
          requestPayload.push(requestData);
        });
        if (isUpdate) {
          CandidateService.updateAddressDetails(id, requestPayload).then(
            (res) => {
              if (res.status === 201 || res.status === 200) {
                props.updateData(res.data.result, props.stepConfig);
                if (userType == "can") {
                  history.push(`/${userType}/profile-view/${id}`);
                } else {
                  history.push(`/${userType}/candidate/view/${id}`);
                }
              }
            },
            (err) => {
              setErrorObj({ show: true, error: err.data });
            }
          );
        } else {
          CandidateService.addAddressDetails(id, requestPayload).then(
            (res) => {
              if (res.status === 201 || res.status === 200) {
                props.updateData(res.data.result, props.stepConfig);
                history.push(`/${userType}/candidate`);
              }
            },
            (err) => {
              setErrorObj({ show: true, error: err.data });
            }
          );
        }
      } else {
        props.updateData(props.wizard.addresses, props.stepConfig);
        if (userType == "can") {
          history.push(`/${userType}/profile-view/${id}`);
        } else {
          history.push(`/${userType}/candidate/view/${id}`);
        }
      }
    },
  }));
  return (
    <div id="professional-step" className="wizard-step-wrapper">
      <div className="step-container">
        <div className="row mb-4">
          <p className="step-info-text m-0 col">
            User need to enterd their professional details here .
          </p>
          <div className="col-auto">
            <button className="btn btn-primary btn-sm" onClick={AddRow}>
              + Add New Row
            </button>
          </div>
        </div>

        <div className="step-content">
          {stepData.map((item, index) => {
            return (
              <AddressForm
                key={index}
                data={item.data}
                updateData={updateData}
                onRemove={removeRow}
                rowIndex={index}
                totalRow={stepData.length}
                stateList={stateList}
                restrictMoveToNext={props.canProceed}
              ></AddressForm>
            );
          })}
        </div>
      </div>
      <ErrorModal
        data={errorObj}
        onConfirmAction={() => setErrorObj({ show: false, error: {} })}
      ></ErrorModal>
    </div>
  );
});

export default AddressStep;
