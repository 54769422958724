import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import Carousel from "react-multi-carousel";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ReceiptIcon from "@material-ui/icons/Receipt";


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const TrendingNews = ({ data: newsList }) => {
  return (
    <div id="trending-news-1">
      <div className="container py-4">
        <div className="row section-header text-center p-4">
          <h2 className="col-md-12">Trending News</h2>
          <p className="col-md-12">Trending news related to medical.</p>
        </div>
        <div className="news-carousel-container px-4 pt-4 mb-4">
          <div className="container p-4">
            <Carousel
              responsive={responsive}
              containerClass="carousel-container py-3"
              itemAriaLabel="News list"
              itemClass="align-items-center justify-content-center"
              arrows={false}
              swipeable={false}
              draggable={false}
              showDots={true}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              transitionDuration={500}
              removeArrowOnDeviceType={["tablet", "mobile"]}

            >
              {newsList?.length &&
                newsList.map((news, index) => (
                  <div className="row no-gutters px-4" key={index}>
                    <div className="col-12">
                      <div className="news-card ">
                        <div className="news-card-header py-3 row no-gutters align-items-center">
                          <h5 className="news-title col-12" title={news.heading}>
                            {news.heading.substring(0, 90)}
                            {news.heading.length > 90 ? '...' : ''}
                          </h5>
                          <div className="news-icon">
                            <ReceiptIcon />
                          </div>
                        </div>
                        <div className="news-card-body">

                          <div className="card-text word-wrap">
                            {ReactHtmlParser(news.content)}
                          </div>

                          <div className="row no-gutters align-items-center pt-2">
                            <div className="col-6 ">
                              <span className="sub-text">
                                <Moment
                                  date={news.postOn}
                                  format={"MMM DD, YYYY"}
                                ></Moment>
                              </span>
                            </div>
                            <div className="col-6 text-right">
                              <Link className="date sub-text" to={`/news/public/${news.id}`}>
                                View News
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingNews;
